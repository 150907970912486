// @flow
import {
  TOGGLE_RIDERS_MODAL,
} from "./actionTypes"

const INIT_STATE = {
  isRidersModalVisible: false,
}

const Modals = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_RIDERS_MODAL:
      return {
        ...state,
        isRidersModalVisible: action.payload,
      }

    default:
      return state
  }
}

export default Modals
