import React, { useState } from "react";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { TableColumn } from "react-data-table-component";
import InvitationDetailsModal from "../../components/Modals/InvitationDetails";
import InvitationFormModal from "../../components/Modals/InvitationEdit";
import Alert from "../../components/Modals/InvitationDeleteAlert";
import { GET_INVITATIONS } from "../../helpers/url_helper";
import { IInvitation } from "../../types";
import { useHttpClient } from "../../context/HttpClient";
import { usePaginatedSWR } from "../../hooks/usePagination";

const invitationDeleted = () => toast.success("Invitation deleted succesfully");
const invitationDeletingError = (err: string) =>
  toast.error("Invitation deleting error:\n" + err);

const Invitations: React.FC = () => {
  const { t } = useTranslation();
  const {
    totalRows,
    entries: invitations,
    getEntriesError: getInvitationsError,
    handlePageChange,
    handlePerRowsChange,
  } = usePaginatedSWR<IInvitation>({
    url: GET_INVITATIONS,
  });
  const {
    state: { deleteInvitation },
  } = useHttpClient();

  const [detailsModal, setDetailsModal] = useState<IInvitation | undefined>();
  const [editModal, setEditModal] = useState<IInvitation | undefined>();
  const [createModal, setCreateModal] = useState<boolean>();
  const [deleteAlertVisible, setDeleteAlertVisible] = useState<
    number | undefined
  >();

  const columns: TableColumn<IInvitation>[] = [
    {
      name: "ID",
      selector: (row) => row.id ?? 0,
      maxWidth: "50px",
    },
    {
      name: "Rider Name",
      selector: (row) => {
        let field = "";
        if (row.first_name) field += row.first_name + " ";
        if (row.last_name) field += row.last_name;

        return field.length ? field : "<blank>";
      },
    },
    {
      name: "Phone number",
      selector: (row) => row.phone_number,
    },
    {
      name: "Email",
      selector: (row) => (row.email ? row.email : "<blank>"),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex gap-3">
          <Button
            type="submit"
            color="primary"
            className="btn-sm ml-3"
            onClick={() => {
              setDetailsModal(row);
            }}
          >
            View details
          </Button>
          <span
            className="link-success"
            onClick={(e) => {
              e.preventDefault();
              setEditModal(row);
            }}
          >
            <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
            <UncontrolledTooltip placement="top" target="edittooltip">
              Edit
            </UncontrolledTooltip>
          </span>

          <span
            className="link-danger"
            onClick={(e) => {
              e.preventDefault();
              setDeleteAlertVisible(row.id);
            }}
          >
            <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            <UncontrolledTooltip placement="top" target="deletetooltip">
              Delete
            </UncontrolledTooltip>
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={t("Home")} breadcrumbItem={t("Invitations")} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center justify-content-between">
                    <CardTitle className="h4">Your invitations</CardTitle>
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={() => setCreateModal(true)}
                    >
                      <i className="mdi mdi-plus me-1" />
                      Create New invitation
                    </Button>
                  </div>
                  <DataTable
                    columns={columns}
                    data={
                      invitations?.filter(
                        (invitation) => invitation.status !== 3
                      ) ?? []
                    }
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressPending={!invitations && !getInvitationsError}
                    highlightOnHover
                    responsive={true}
                    defaultSortFieldId={1}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </div>
      </div>

      <InvitationDetailsModal
        data={detailsModal}
        onClose={() => setDetailsModal(undefined)}
      />
      <InvitationFormModal
        title={"Edit Invitation"}
        isOpen={!!editModal}
        data={editModal}
        onClose={() => {
          setEditModal(undefined);
        }}
      />
      <InvitationFormModal
        title={"Create Invitation"}
        data={undefined}
        isOpen={!!createModal}
        onClose={() => {
          setCreateModal(undefined);
        }}
      />
      <Alert
        isOpen={deleteAlertVisible !== undefined}
        onSubmit={() => {
          if (!deleteAlertVisible) return;
          deleteInvitation(deleteAlertVisible)
            .then((resp: any) => {
              toast.dismiss();
              invitationDeleted();
            })
            .catch((err: any) => {
              toast.dismiss();
              invitationDeletingError(err);
              console.log(err);
            });
          setDeleteAlertVisible(undefined);
        }}
        onClose={() => setDeleteAlertVisible(undefined)}
      />
    </>
  );
};

export default Invitations;
