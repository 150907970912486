import { useEffect, useState } from "react";

import DataTable, {
  ConditionalStyles,
  TableColumn,
} from "react-data-table-component";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

import { useTranslation } from "react-i18next";
import { IUser } from "../../types";
import { ChangeRiderPasswordModal } from "../../components/Modals/ChangeRiderPassword";
import { GET_USERS } from "../../helpers/url_helper";
import { usePaginatedSWR } from "../../hooks/usePagination";

const Riders = () => {
  const { t } = useTranslation();

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [activeRider, setActiveRider] = useState<IUser | null>(null);

  const [textFilter, setTextFilter] = useState("");
  const [filteredResults, setFilteredResults] = useState<IUser[]>([]);

  const {
    totalRows,
    entries: riders,
    getEntriesError: getRidersError,
    handlePageChange,
    handlePerRowsChange,
  } = usePaginatedSWR<IUser>({
    url: GET_USERS,
  });

  const conditionalRowStyles: ConditionalStyles<IUser>[] = [
    {
      when: (row) => !row.groups.length,
      style: {
        backgroundColor: "rgba(244, 106, 106, 0.18)",
      },
    },
  ];

  const columns: TableColumn<IUser>[] = [
    {
      name: "Rider ID",
      selector: (row) => row.id,
    },
    {
      name: "Name",
      selector: (row) => `${row.first_name} ${row.last_name}`,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone",
      selector: (row) => row.phone_number,
    },
    {
      name: "Country",
      selector: (row) => row.country,
    },
    {
      name: "City",
      selector: (row) => row.city,
    },
    {
      name: "Zip code",
      selector: (row) => row.zip_code,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex gap-3">
          <span
            className="link-info d-flex align-items-center cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              setActiveRider(row);
              setIsChangePasswordModalOpen(true);
            }}
          >
            Change password
          </span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!riders) return;
    setFilteredResults(
      riders.filter((rider) => {
        return (
          rider.first_name.toLowerCase().includes(textFilter) ||
          rider.last_name.toLowerCase().includes(textFilter) ||
          (rider.email && rider.email.toLowerCase().includes(textFilter)) ||
          (rider.phone_number &&
            rider.phone_number.toLowerCase().includes(textFilter))
        );
      })
    );
  }, [riders, textFilter]);

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={t("Home")} breadcrumbItem={t("Riders")} />
          <Row>
            <Col className="col-12">
              <Card style={{ height: "95%" }}>
                <CardBody>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        gap: 40,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardTitle>{t("Riders List")}</CardTitle>
                    </div>
                    <form
                      className="search-box d-inline-block"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <div className="position-relative">
                        <input
                          style={{ backgroundColor: "#f3f3f9", border: "none" }}
                          type="text"
                          className="form-control"
                          placeholder="Filter ..."
                          onChange={(e) => {
                            setTextFilter(e.target.value.toLowerCase());
                          }}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </form>
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredResults}
                    conditionalRowStyles={conditionalRowStyles}
                    highlightOnHover
                    progressPending={!riders && !getRidersError}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ChangeRiderPasswordModal
        rider={activeRider}
        isOpen={isChangePasswordModalOpen}
        onClose={() => setIsChangePasswordModalOpen(false)}
      />
    </>
  );
};

export default Riders;
