import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Badge,
} from "reactstrap";
import avatar1 from "../../../assets/images/users/avatar-1.jpg";

import WrappedMap from "../../Maps/GoogleMap/WrappedMap";

import { Link } from "react-router-dom";
import LineColumnArea from "../../Charts/LineColumnArea";

const RiderDetails = ({ isOpen, onClose }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          onClose();
        }}
        style={{
          maxWidth: 1200,
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              onClose();
            }}
          >
            Rider details
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <Row>
                  <Col lg="2">
                    <div className="text-lg-center">
                      <img
                        src={avatar1}
                        className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none rounded-circle"
                        alt="img"
                      />
                    </div>
                  </Col>

                  <Col lg="3">
                    <h5 className="mb-1 font-size-15 text-truncate">
                      John Doe
                    </h5>
                    <Badge
                      className="font-size-12 badge-soft-success"
                      color="success"
                      pill
                    >
                      On road
                    </Badge>
                  </Col>
                  <Col lg="3">
                    <i className="mdi mdi-star text-warning font-size-20" />
                    <i className="mdi mdi-star text-warning font-size-20" />
                    <i className="mdi mdi-star text-warning font-size-20" />
                    <i className="mdi mdi-star text-warning font-size-20" />
                    <i className="mdi mdi-star-outline text-muted font-size-20" />
                  </Col>
                </Row>
                <p className="mb-4 font-size-15">General:</p>
                <div style={{ margin: "0 10px" }}>
                  <p className="mb-1 font-size-15">ID:</p>
                  <p className="mb-4 font-size-12">USR001</p>
                  <p className="mb-1 font-size-15">Email:</p>
                  <p className="mb-4 font-size-12">johndoe@example.com</p>
                  <p className="mb-1 font-size-15">Phone:</p>
                  <p className="mb-4 font-size-12">+123123123</p>
                  <p className="mb-1 font-size-15">Joined:</p>
                  <p className="mb-4 font-size-12">07 Oct, 2019</p>
                  <p className="mb-1 font-size-15">
                    Vehicles assigned (scooters/bikes/mopeds):
                  </p>
                  <p className="mb-4 font-size-12">1 (1/0/0)</p>
                  <p className="mb-1 font-size-15">Location:</p>
                  <p className="mb-4 font-size-12">Barcelona, El Raval</p>

                  <WrappedMap
                    height="200px"
                    init_zoom={13}
                    init_center={{ lat: 41.385381, lng: 2.173308 }}
                    area={[
                      { lat: 41.385381, lng: 2.163308 },
                      { lat: 41.385381, lng: 2.183308 },
                      { lat: 41.395381, lng: 2.183308 },
                      { lat: 41.39381, lng: 2.163308 },
                    ]}
                  />
                </div>
              </Col>
              <Col>
                <p className="mb-4 font-size-15">Statistics:</p>
                <p className="mb-1 font-size-15">
                  Overall assignation time (scooters/bikes/mopeds):
                </p>
                <p className="mb-4 font-size-12">720 (712/8/0)</p>
                <p className="mb-1 font-size-15">
                  Hours on the road (scooters/bikes/mopeds):
                </p>
                <p className="mb-4 font-size-12">576 (570/6/0)</p>
                <LineColumnArea />
                <p className="mb-4 font-size-15">Notes:</p>
                <p className="mb-4 font-size-12">-</p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </Button>
            <Button type="button" color="primary" disabled>
              Edit info
            </Button>
            <Link to="/history">
              <Button type="button" color="primary">
                Show history
              </Button>
            </Link>
            <Link to="/assignments">
              <Button type="button" color="primary">
                Assign/unassign vehicles
              </Button>
            </Link>
            <Button type="button" color="primary" disabled>
              Chat with rider
            </Button>
            <Button type="button" color="danger" disabled>
              Deactivate rider
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

export default RiderDetails;
