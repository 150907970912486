import { useEffect, useState } from "react";

import DataTable, { TableColumn } from "react-data-table-component";

import MapModal from "../../components/Modals/MapModal";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import VehicleDetails from "../../components/Modals/VehicleDetails";

import { Link } from "react-router-dom";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { useTranslation } from "react-i18next";

import { IConnector } from "../../types";
import { GET_CONNECTORS } from "../../helpers/url_helper";
import { usePaginatedSWR } from "../../hooks/usePagination";

const Vehicles = () => {
  const { t } = useTranslation();
  const [, setSelectedRows] = useState<IConnector[]>([]);

  const {
    totalRows,
    entries: vehicles,
    getEntriesError: getVehiclesError,
    handlePageChange,
    handlePerRowsChange,
  } = usePaginatedSWR<IConnector>({
    url: GET_CONNECTORS,
    config: {
      refreshInterval: 10000,
    },
  });

  const [textFilter, setTextFilter] = useState("");
  const [filteredResults, setFilteredResults] = useState<IConnector[]>();

  useEffect(() => {
    if (vehicles) {
      setFilteredResults(
        vehicles.filter((vehicle) => {
          return (
            vehicle.device_id?.toString().toLowerCase().includes(textFilter) ||
            vehicle.name?.toLowerCase().includes(textFilter) ||
            vehicle.user_id?.toString().toLowerCase().includes(textFilter)
          );
        })
      );
    }
  }, [vehicles, textFilter]);

  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [isVehicleDetailsOpen, setIsVehicleDetailsOpen] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<{
    longitude: number;
    latitude: number;
  } | null>(null);

  const columns: TableColumn<IConnector>[] = [
    {
      name: "ID",
      selector: (row) => row.device_id,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "User id",
      selector: (row) => row.user_id,
    },
    {
      name: "Last known location",
      cell: (row) => (
        <span
          className={
            row.last_position_longitude && row.last_position_latitude
              ? "link-primary"
              : "link-disabled"
          }
          onClick={(e) => {
            e.preventDefault();
            if (!row.last_position_longitude || !row.last_position_latitude)
              return;
            setIsMapModalOpen(true);
            setCurrentLocation({
              longitude: parseFloat(row.last_position_longitude),
              latitude: parseFloat(row.last_position_latitude),
            });
          }}
        >
          {row.last_position_longitude && row.last_position_latitude
            ? "Open map"
            : "Unknown"}
        </span>
      ),
    },
    {
      name: "Actions",
      grow: 3,
      cell: () => <Actions />,
    },
  ];

  const handleChange = ({
    selectedRows,
  }: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: IConnector[];
  }) => {
    setSelectedRows(selectedRows);
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={t("Home")} breadcrumbItem={t("Vehicles")} />
          <Row>
            <Col className="col-12">
              <Card style={{ height: "95%" }}>
                <CardBody>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <CardTitle>{t("Your vehicles list")}</CardTitle>
                    <form
                      className="search-box d-inline-block"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <div className="position-relative">
                        <input
                          style={{ backgroundColor: "#f3f3f9", border: "none" }}
                          type="text"
                          className="form-control"
                          placeholder="Filter ..."
                          onChange={(e) => {
                            setTextFilter(e.target.value.toLowerCase());
                          }}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </form>
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredResults ?? []}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressPending={!vehicles && !getVehiclesError}
                    selectableRows
                    onSelectedRowsChange={handleChange}
                    highlightOnHover
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <MapModal
        lat={currentLocation?.latitude}
        lng={currentLocation?.longitude}
        isOpen={isMapModalOpen}
        onClose={() => setIsMapModalOpen(false)}
      />
      <VehicleDetails
        isOpen={isVehicleDetailsOpen}
        onClose={() => setIsVehicleDetailsOpen(false)}
      />
    </>
  );
};

const Actions = () => {
  return (
    <>
      <div
        style={{
          width: "260px",
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          alignItems: "center",
          gap: "10px",
          wordBreak: "initial",
        }}
      >
        <Link to="/assignments">Assign/ Unassign</Link>
      </div>
    </>
  );
};

export default Vehicles;
