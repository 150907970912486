import { memo } from "react";
import { useDrop } from "react-dnd";
import { Button } from "reactstrap";
import { IHub } from "../../../types";
import { BASKET_NAMES } from "./BasketNames";
import { createChangeUserBasket } from "./helpers";
import { User } from "./User";
import { useSelectItems } from "./useSelectUsers";

interface IVehicleCategoryProps {
  name: string;
  usersInside?: { username: string; id: number }[];
  avaliablePlaces?: number;
  baskets: any[];
  setBaskets: any;
  hubSelected: IHub;
}

export const VehicleCategory = memo(function VehicleCategory({
  name,
  usersInside,
  avaliablePlaces,
  baskets,
  setBaskets,
  hubSelected,
}: IVehicleCategoryProps) {
  const { selectedItems, handleItemSelection, clearItemSelection } =
    useSelectItems(usersInside);
  const [{ isOver, canDrop, beginDrag }, drop] = useDrop({
    accept: "USER",
    drop: () => ({ name: name }),
    canDrop: () => (avaliablePlaces ?? 0) > 0,
    collect: (monitor) => ({
      beginDrag: monitor.getItem(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = isOver && canDrop && beginDrag;
  let backgroundColor = "#fff";
  if (isActive) {
    backgroundColor = "#82E0AA";
  } else if (canDrop && beginDrag) {
    backgroundColor = "#ABEBC6";
  } else if (beginDrag) {
    backgroundColor = "#E74C3C";
  }

  const changeUserBasket = createChangeUserBasket(
    name,
    baskets,
    hubSelected,
    setBaskets
  );

  const switchBasket = async ({
    item,
  }: {
    item: { id: number; username: string };
  }) => {
    await changeUserBasket(item, BASKET_NAMES.USERS);
  };

  return (
    <>
      {selectedItems.length > 0 && (
        <Button
          color="primary"
          size="sm"
          className="btn-sm btn-rounded"
          onClick={async () => {
            await Promise.all(
              selectedItems.map(async (item) => {
                await switchBasket({ item });
              })
            );
            clearItemSelection();
          }}
        >
          Unassign users
        </Button>
      )}
      <div
        ref={drop}
        style={{ backgroundColor }}
        data-testid="dustbin"
        className="hub-box-bin"
      >
        <div className="hub-box-bin-head">
          <span>{name}</span>
        </div>
        {usersInside
          ?.sort((a, b) => a.username?.localeCompare(b.username))
          .map(({ id, username }, index) => (
            <User
              key={index}
              id={id}
              username={username}
              currentBasketName={name}
              baskets={baskets}
              setBaskets={setBaskets}
              hubSelected={hubSelected}
              handleSelection={handleItemSelection}
              index={index}
              isSelected={
                selectedItems.filter((item) => item.id === id).length > 0
              }
            />
          ))}
      </div>
    </>
  );
});
