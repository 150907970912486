import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
} from "reactstrap";
import WrappedMap from "../../Maps/GoogleMap/WrappedMap";

import { useTranslation } from "react-i18next";
import { GET_DOCK } from "../../../helpers/url_helper";
import { IDock, MarkerTypeEnum } from "../../../types";
import { useAuthenticatedSWR } from "../../../hooks/useAuthenticatedSWR";

interface IDockDetailsProps {
  dockId?: string;
  isOpen: boolean;
  onClose: () => void;
}

const DockDetails = ({ dockId, isOpen, onClose }: IDockDetailsProps) => {
  const { data: dock } = useAuthenticatedSWR<IDock>(
    dockId ? `${GET_DOCK}${dockId}` : ""
  );
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      toggle={() => {
        onClose();
      }}
      style={{
        maxWidth: 1200,
      }}
    >
      <div>
        <ModalHeader
          toggle={() => {
            onClose();
          }}
        >
          {t("Dock details")}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div style={{ margin: "0 10px" }}>
                <p className="mb-1 font-size-15">ID:</p>
                <p className="mb-4 font-size-12">{dock?.id}</p>
                <p className="mb-1 font-size-15">Dock number:</p>
                <p className="mb-4 font-size-12">{dock?.device_id}</p>
                <p className="mb-1 font-size-15">Hub ID:</p>
                <p className="mb-4 font-size-12">
                  {dock?.hub.id}&nbsp;&nbsp;&nbsp;
                </p>
                <p className="mb-1 font-size-15">Last used:</p>
                <p className="mb-4 font-size-12">
                  {dock?.connector.last_position_updated_at}
                </p>
                <p className="mb-1 font-size-15">Hub address:</p>
                <p className="mb-4 font-size-12">
                  {dock?.hub.address}&nbsp;&nbsp;&nbsp;
                </p>
                {dock?.hub ? (
                  <>
                    <p className="mb-1 font-size-15">Hub location:</p>
                    <WrappedMap
                      init_zoom={13}
                      height="200px"
                      init_center={{
                        lat: parseFloat(dock.hub.latitude),
                        lng: parseFloat(dock.hub.longitude),
                      }}
                      markers_arr={[
                        {
                          lat: parseFloat(dock.hub.latitude),
                          lng: parseFloat(dock.hub.longitude),
                          infoWindow: dock.hub.name,
                          type: MarkerTypeEnum.HUB,
                        },
                      ]}
                      onMarkerClick={undefined}
                    />
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default DockDetails;
