import React from "react";

import { Link } from "react-router-dom";

import logoWideLight from "../../assets/images/logo-wide-light.png";
import logoWideDark from "../../assets/images/logo-wide-dark.png";

//Components imports
import ProfileMenu from "../Common/TopbarDropdown/ProfileMenu";
import { useDispatch, useSelector } from "react-redux";

// Redux Store
import { toggleLeftmenu } from "../../store/actions";

const Header = () => {
  const dispatch = useDispatch();
  const leftMenu = useSelector((state) => state.Layout.leftMenu);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoWideDark} alt="" height="17" />
                </span>
                <span className="logo-lg">
                  <img src={logoWideDark} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoWideLight} alt="" height="17" />
                </span>
                <span className="logo-lg">
                  <img src={logoWideLight} alt="" height="22" />
                </span>
              </Link>
            </div>
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                dispatch(toggleLeftmenu(!leftMenu));
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>

          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                className="btn header-item noti-icon "
                onClick={() => {
                  toggleFullscreen();
                }}
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
