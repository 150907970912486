export interface INotification {
  id: string;
  content: string;
  user_id: number;
  title: string;
  priority: number;
  source: 0 | 1 | 2;
  created: string;
  modified: string;
  is_read?: boolean;
  link?: string;
}

export interface IDock {
  id: string;
  device_id: number;
  name: string;
  hub: IHub;
  connector: IConnector;
}

export interface IHub {
  id: string;
  name: string;
  address: string;
  latitude: string;
  longitude: string;
}

export interface IConnector {
  device_id: number;
  name: string;
  user_id: number;
  last_position_latitude: string;
  last_position_longitude: string;
  last_position_updated_at: string;
  gps_tracker_imei?: string;
}

export interface IUser {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  country: string;
  city: string;
  zip_code: string;
  company: ICompany;
  groups: IGroup[];
}

export type IPaginatedResult<T> =
  | {
      count: number;
      next: string | null;
      previous: string | null;
      results: T[];
    }
  | T[];

export interface ICompany {
  id: number;
  short_name: string;
  registered_name: string;
  avatar: string;
  allowed_hubs: string[];
}

export interface IGroup {
  name: string;
}

export enum AuthActionTypeEnum {
  RESTORE_TOKEN,
  SIGN_IN,
  SIGN_OUT,
  REFETCH_USER,
}

export type IAuthActions =
  | {
      type: AuthActionTypeEnum.RESTORE_TOKEN;
      accessToken?: string | null;
      user?: IUser | null;
    }
  | {
      type: AuthActionTypeEnum.REFETCH_USER;
      user: IUser;
    }
  | {
      type: AuthActionTypeEnum.SIGN_IN;
      accessToken: string;
      refreshToken: string;
      user?: IUser | null;
    }
  | {
      type: AuthActionTypeEnum.SIGN_OUT;
    };

export interface IUpdateInvitationInput {
  id: number;
  phone_number: string;
  first_name: string;
  last_name: string;
  email: string;
  notes: string;
  status: number;
  default_allowed_hubs: string[];
}

export type ICreateInvitationInput = Omit<IUpdateInvitationInput, "id">;

export interface ILoginInput {
  username: string;
  password: string;
}

export interface IPostResetPasswordInput {
  user_id: IUser["id"];
  password: string;
  password_confirmation: string;
}

export type IPasswordResetInput = Omit<IPostResetPasswordInput, "user_id">;

export interface IInvitation {
  content_plain_text: string;
  default_allowed_hubs: string[];
  email: string;
  first_name: string;
  id?: number;
  last_name: string;
  notes: string;
  phone_number: string;
  status: number;
}

export enum MarkerTypeEnum {
  BIKE = "bike",
  SCOOTER = "scooter",
  MOPED = "moped",
  CARGO = "cargo",
  HUB = "hub",
}
