import Select, { GroupBase, Props } from "react-select";

const CustomSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, Group> & { isValid: boolean }
) => {
  const { isValid } = props;

  const customStyles = {
    control: (base: any, state: any) =>
      isValid
        ? { ...base }
        : {
            ...base,
            boxShadow: state.isFocused
              ? "0 0 0 0.15rem rgba(244, 106, 106, 0.25)"
              : "unset",
            borderColor: "#f46a6a",
            paddingRight: "calc(1.5em + 0.94rem)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right calc(0.375em + 0.235rem) center",
            backgroundSize: "calc(0.75em + 0.47rem) calc(0.75em + 0.47rem)",
            backgroundColor: "#fff",
          },
  };
  return <Select styles={customStyles} {...props} />;
};

export default CustomSelect;
