import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SWRConfig } from "swr";
import { NotificationsProvider } from "./context/Notifications";
import { HttpClientStateProvider } from "./context/HttpClient";
import { AuthStateProvider } from "./context/Auth";
import { ErrorFallback } from "./components/Common/ErrorFallback";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
    <SWRConfig
      value={{
        refreshInterval: 60000,
      }}
    >
      <Provider store={store}>
        <BrowserRouter>
          <HttpClientStateProvider>
            <AuthStateProvider>
              <NotificationsProvider>
                <App />
              </NotificationsProvider>
            </AuthStateProvider>
          </HttpClientStateProvider>
        </BrowserRouter>
      </Provider>
    </SWRConfig>
  </Sentry.ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
