import { useFormik } from "formik";
import { Row, Col, FormFeedback, Input, Label, Button } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import { useHttpClient } from "../../../context/HttpClient";
import { GET_COMPANY_HUBS, GET_INVITATIONS } from "../../../helpers/url_helper";
import { useSWRConfig } from "swr";
import SelectWithValidation from "../CustomInputs/SelectWithValidation";
import { useTranslation } from "react-i18next";
import { IHub, IInvitation } from "../../../types";
import { useAuthenticatedSWR } from "../../../hooks/useAuthenticatedSWR";

const invitationSent = () => toast.success("Invitation created succesfully");
const invitationSendingError = (err: string) =>
  toast.error("Invitation creation error:\n" + err);
const invitationUpdated = () => toast.success("Invitation updated succesfully");
const invitationUpdatingError = (err: string) =>
  toast.error("Invitation updating error:\n" + err);

const InvitationForm = ({
  invitationFields,
  onFormSubmit,
}: {
  invitationFields?: IInvitation;
  onFormSubmit: () => void;
}) => {
  const { t } = useTranslation();
  const {
    state: { createInvitation, updateInvitation },
  } = useHttpClient();
  const { data: hubs } = useAuthenticatedSWR<IHub[]>(`${GET_COMPANY_HUBS}/`);
  const { mutate } = useSWRConfig();
  const formik = useFormik({
    initialValues: {
      phone_number: invitationFields ? invitationFields.phone_number : "",
      first_name: invitationFields?.first_name ?? "",
      last_name: invitationFields?.last_name ?? "",
      email: invitationFields?.email ?? "",
      notes: invitationFields?.notes ?? "",
      default_allowed_hubs: invitationFields?.default_allowed_hubs ?? [],
      id: invitationFields ? invitationFields.id : undefined,
      content_plain_text: invitationFields?.content_plain_text ?? "",
      status: invitationFields?.status ?? 1,
    },
    validate: (values: any) => {
      let errors: any = {};

      if (!values.phone_number) {
        errors.phone_number = "Required field";
      } else if (!/^[0-9]{7,14}$/i.test(values.phone_number)) {
        errors.phone_number = "Phone number is too short";
      }
      if (!values.default_allowed_hubs.length) {
        errors.default_allowed_hubs = "Required to choose at least one hub";
      }
      if (
        values.email.length > 0 &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      if (
        values.last_name.length > 0 &&
        !/^[A-Z]{1,200}$/i.test(values.last_name)
      ) {
        errors.last_name = "Last name contains unexpected characters";
      } else if (values.last_name.length > 0 && values.last_name.length < 2) {
        errors.last_name = "Last name is too short";
      } else if (values.last_name.length > 30) {
        errors.last_name = "Last name is too long";
      }
      if (
        values.first_name.length > 0 &&
        !/^[A-Z]{1,200}$/i.test(values.first_name)
      ) {
        errors.first_name = "First name contains unexpected characters";
      } else if (values.first_name.length > 0 && values.first_name.length < 2) {
        errors.first_name = "First name is too short";
      } else if (values.first_name.length > 30) {
        errors.first_name = "First name is too long";
      }

      return errors;
    },

    onSubmit: (values: IInvitation, { setSubmitting, resetForm }) => {
      if (values.id === undefined) {
        createInvitation({
          phone_number: values.phone_number,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          notes: values.notes,
          status: 0,
          default_allowed_hubs: values.default_allowed_hubs,
        })
          .then((resp: any) => {
            setSubmitting(false);
            resetForm();
            toast.dismiss();
            invitationSent();
            onFormSubmit();
            mutate(GET_INVITATIONS);
          })
          .catch((err: any) => {
            setSubmitting(false);
            toast.dismiss();
            invitationSendingError(err);
            console.log(err);
          });
      } else {
        updateInvitation({
          id: values.id,
          phone_number: values.phone_number,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          notes: values.notes,
          status: 0,
          default_allowed_hubs: values.default_allowed_hubs,
        })
          .then((resp: any) => {
            setSubmitting(false);
            toast.dismiss();
            invitationUpdated();
            onFormSubmit();
            mutate(GET_INVITATIONS);
          })
          .catch((err: any) => {
            setSubmitting(false);
            toast.dismiss();
            invitationUpdatingError(err);
            console.log(err);
          });
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <p className="card-title-desc mb-2">Required fields*</p>
        <Row className="mb-3">
          <Label htmlFor="phone_number" className="col-md-3 col-form-label">
            Phone number*
          </Label>
          <div className="col-md-9">
            <PhoneInput
              inputProps={{
                id: "phone_number",
                type: "text",
              }}
              inputClass="form-control"
              enableSearch
              disableSearchIcon
              country={"pl"}
              isValid={
                !formik.touched.phone_number || !formik.errors.phone_number
                  ? true
                  : false
              }
              value={formik.values.phone_number}
              onChange={(value) => formik.setFieldValue("phone_number", value)}
              onBlur={formik.handleBlur}
            />
            {formik.errors.phone_number && formik.touched.phone_number && (
              <FormFeedback style={{ display: "block" }} type="invalid">
                {(formik.errors as any).phone_number}
              </FormFeedback>
            )}
          </div>
        </Row>
        <Row className="mb-3">
          <label
            htmlFor="default_allowed_hubs"
            className="col-md-3 col-form-label"
          >
            Allowed hubs*
          </label>
          <div className="col-md-9">
            <SelectWithValidation
              isValid={
                !formik.touched.default_allowed_hubs ||
                !formik.errors.default_allowed_hubs
                  ? true
                  : false
              }
              value={formik.values.default_allowed_hubs.map((hub_id) => {
                const hub = hubs?.find((hub) => hub.id === hub_id);

                return {
                  value: hub_id,
                  label: hub?.name,
                };
              })}
              isMulti={true}
              onChange={(value) =>
                formik.setFieldValue(
                  "default_allowed_hubs",
                  value.map((v) => v.value)
                )
              }
              options={
                hubs?.map((hub) => ({
                  value: hub.id,
                  label: hub.name,
                })) ?? []
              }
              onBlur={formik.handleBlur}
              classNamePrefix="default_allowed_hubs-selection"
              inputId="default_allowed_hubs"
            />
            {formik.errors.default_allowed_hubs &&
              formik.touched.default_allowed_hubs && (
                <FormFeedback style={{ display: "block" }} type="invalid">
                  {(formik.errors as any).default_allowed_hubs}
                </FormFeedback>
              )}
          </div>
        </Row>
        <p className="card-title-desc">{t("Optional fields")}</p>
        <Row className="mb-3">
          <label htmlFor="first_name" className="col-md-3 col-form-label">
            First name
          </label>
          <div className="col-md-9">
            <Input
              id="first_name"
              className="form-control"
              type="text"
              placeholder="Enter First Name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              invalid={
                formik.touched.first_name && formik.errors.first_name
                  ? true
                  : false
              }
              onBlur={formik.handleBlur}
            />
            {formik.errors.first_name && formik.touched.first_name && (
              <FormFeedback type="invalid">
                {(formik.errors as any).first_name}
              </FormFeedback>
            )}
          </div>
        </Row>
        <Row className="mb-3">
          <label htmlFor="last_name" className="col-md-3 col-form-label">
            Last name
          </label>
          <div className="col-md-9">
            <Input
              id="last_name"
              className="form-control"
              type="text"
              placeholder="Enter Last Name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              invalid={
                formik.touched.last_name && formik.errors.last_name
                  ? true
                  : false
              }
              onBlur={formik.handleBlur}
            />
            {formik.errors.last_name && formik.touched.last_name && (
              <FormFeedback type="invalid">
                {(formik.errors as any).last_name}
              </FormFeedback>
            )}
          </div>
        </Row>
        <Row className="mb-3">
          <Label htmlFor="email" className="col-md-3 col-form-label">
            {t("Email")}
          </Label>
          <div className="col-md-9">
            <Input
              id="email"
              placeholder="Enter Email"
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange}
              className="form-control"
              invalid={
                formik.touched.email && formik.errors.email ? true : false
              }
              onBlur={formik.handleBlur}
            />
            {formik.errors.email && formik.touched.email && (
              <FormFeedback type="invalid">
                {(formik.errors as any).email}
              </FormFeedback>
            )}
          </div>
        </Row>
        <Row className="mb-3">
          <label htmlFor="notes" className="col-md-3 col-form-label">
            {t("Notes")}
          </label>
          <div className="col-md-9">
            <textarea
              id="notes"
              rows={2}
              placeholder="Enter Your Notes"
              value={formik.values.notes}
              onChange={formik.handleChange}
              className={
                "form-control" +
                (formik.touched.notes && formik.errors.notes
                  ? " is-invalid"
                  : "")
              }
              onBlur={formik.handleBlur}
            />
            {formik.errors.notes && formik.touched.notes && (
              <FormFeedback style={{ display: "block" }} type="invalid">
                {(formik.errors as any).notes}
              </FormFeedback>
            )}
          </div>
        </Row>

        <Row>
          <Col>
            <div className="d-flex flex-wrap gap-2">
              <Button
                type="submit"
                color="primary"
                className="btn-md ml-3"
                disabled={formik.isSubmitting}
              >
                Submit
              </Button>
              <Button
                type="reset"
                color="secondary"
                className="btn-md"
                onClick={formik.handleReset}
                disabled={!formik.dirty || formik.isSubmitting}
              >
                Reset
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default InvitationForm;
