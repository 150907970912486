export const POST_LOGIN = "/oauth/token/";
export const GET_COMPANY_HUBS = "/v1/company-hubs";
export const GET_NOTIFICATIONS = "/v1/notifications/";
export const GET_DOCKS = "/v1/hubs/docks";
export const GET_DOCK = "/v1/docks/";
export const GET_CONNECTORS = "/v1/connectors";
export const GET_USERS = "/v1/users";
export const POST_RESET_PASSWORD = "/v1/users/reset_password/";
export const GET_CURRENT_USER = "/v1/users/current/";
export const GET_INVITATIONS = "/v1/invitations";
export const GET_HUBS = "/v1/hubs";
