import { useEffect, useState } from "react";

import DataTable, { TableColumn } from "react-data-table-component";
import WrappedMap from "../../components/Maps/GoogleMap/WrappedMap";

import MapModal from "../../components/Modals/MapModal";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import Alert from "../../components/Modals/Alert";

import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import { useTranslation } from "react-i18next";

import { IHub, MarkerTypeEnum } from "../../types";
import { usePaginatedSWR } from "../../hooks/usePagination";
import { GET_COMPANY_HUBS } from "../../helpers/url_helper";

const Hubs = () => {
  const { t } = useTranslation();
  const [, setSelectedRows] = useState<IHub[]>([]);
  const [textFilter, setTextFilter] = useState<string>("");

  const {
    totalRows,
    entries: hubs,
    getEntriesError: getHubsError,
    handlePageChange,
    handlePerRowsChange,
  } = usePaginatedSWR<IHub>({
    url: GET_COMPANY_HUBS,
  });

  const [isMapModalOpen, setIsMapModalOpen] = useState(false);

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [filteredResults, setFilteredResults] = useState<IHub[]>([]);

  useEffect(() => {
    const filteredHubs = hubs?.filter(
      (hub) =>
        hub.id.toLowerCase().includes(textFilter) ||
        hub.address.toLowerCase().includes(textFilter) ||
        hub.name.toLowerCase().includes(textFilter)
    );
    setFilteredResults(filteredHubs ?? []);
  }, [hubs, textFilter]);
  const [currentLocation, setCurrentLocation] = useState<{
    longitude: number;
    latitude: number;
  } | null>(null);

  const columns: TableColumn<IHub>[] = [
    {
      name: "Hub ID",
      selector: (row) => row.id,
    },
    {
      name: "Hub name",
      selector: (row) => row.name,
    },
    {
      name: "Hub location",
      cell: (row) => (
        <span
          className="link-primary"
          onClick={(e) => {
            e.preventDefault();
            setIsMapModalOpen(true);
            setCurrentLocation({
              longitude: parseFloat(row.longitude),
              latitude: parseFloat(row.latitude),
            });
          }}
        >
          {row.address}
        </span>
      ),
    },
  ];

  const handleChange = ({
    selectedRows,
  }: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: IHub[];
  }) => {
    setSelectedRows(selectedRows);
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={t("Home")} breadcrumbItem={t("Hubs")} />
          <Row>
            <Col className="col-12">
              <Card style={{ height: "90%" }}>
                <CardBody>
                  <CardTitle>{t("Search tools")}</CardTitle>
                  <div
                    style={{
                      display: "flex",
                      gap: 20,
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <form
                      className="search-box d-inline-block"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <div className="position-relative">
                        <input
                          style={{ backgroundColor: "#f3f3f9", border: "none" }}
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          onChange={(e) => {
                            setTextFilter(e.target.value.toLowerCase());
                          }}
                        />

                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-12">
            <Col className="col-xl-6 col-12 col-lg-5">
              <Card style={{ height: "95%" }}>
                <CardBody>
                  <CardTitle>{t("Hubs on map")}</CardTitle>
                  {hubs && hubs.length && (
                    <WrappedMap
                      init_zoom={11}
                      height="400px"
                      init_center={{
                        lat: parseFloat(hubs[0].latitude),
                        lng: parseFloat(hubs[0].longitude),
                      }}
                      markers_arr={
                        hubs.map((hub) => ({
                          lat: parseFloat(hub.latitude),
                          lng: parseFloat(hub.longitude),
                          infoWindow: hub.name,
                          type: MarkerTypeEnum.HUB,
                          // TODO
                        })) as any
                      }
                      onMarkerClick={(selectedId: string) => {
                        setFilteredResults(
                          hubs?.filter((hub) => hub.id === selectedId) ?? []
                        );
                        setTextFilter("");
                      }}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col className="col-xl-6 col-12 col-lg-7">
              <Card style={{ height: "95%" }}>
                <CardBody>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <CardTitle>
                      {t("List of hubs available for company")}
                    </CardTitle>
                    <Button
                      type="button"
                      color="primary"
                      className="btn-sm mb-3"
                      onClick={() => {
                        setFilteredResults(hubs ?? []);
                        setTextFilter("");
                      }}
                    >
                      Reset filters
                    </Button>
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredResults}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressPending={!hubs && !getHubsError}
                    onSelectedRowsChange={handleChange}
                    highlightOnHover
                    responsive={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <MapModal
        isOpen={isMapModalOpen}
        lat={currentLocation?.latitude}
        lng={currentLocation?.longitude}
        onClose={() => setIsMapModalOpen(false)}
      />
      <Alert
        type="Success"
        description="Lorem ipsum"
        isOpen={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
      />
    </>
  );
};

export default Hubs;
