import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Badge,
} from "reactstrap";
import { Link } from "react-router-dom";
import WrappedMap from "../../Maps/GoogleMap/WrappedMap";

import LineColumnArea from "../../Charts/LineColumnArea";
import Dountchart from "../../Charts/Dountchart";

const VehicleDetails = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={() => {
        onClose();
      }}
      style={{
        maxWidth: 1200,
      }}
    >
      <div>
        <ModalHeader
          toggle={() => {
            onClose();
          }}
        >
          Vehicle details
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div style={{ margin: "0 10px" }}>
                <p className="mb-1 font-size-15">ID:</p>
                <p className="mb-4 font-size-12">0001</p>
                <p className="mb-1 font-size-15">Type:</p>
                <p className="mb-4 font-size-12">
                  Scooter&nbsp;&nbsp;&nbsp;
                  <Badge
                    className="font-size-12 badge-soft-success"
                    color="success"
                    pill
                  >
                    Ready
                  </Badge>
                </p>
                <p className="mb-1 font-size-15">Hub ID:</p>
                <p className="mb-4 font-size-12">
                  BARS001&nbsp;&nbsp;&nbsp;
                  <span className="text-primary">Go to hub {">"}</span>
                </p>
                <p className="mb-1 font-size-15">Dock number:</p>
                <p className="mb-4 font-size-12">1</p>
                <p className="mb-1 font-size-15">Subscribtion purchased:</p>
                <p className="mb-4 font-size-12">07 Oct, 2019</p>
                <p className="mb-1 font-size-15">Subscribtion ends:</p>
                <p className="mb-4 font-size-12">
                  08 Oct, 2019&nbsp;&nbsp;&nbsp;
                  <span className="text-primary">Extend {">"}</span>
                </p>
                <p className="mb-1 font-size-15">Last used:</p>
                <p className="mb-4 font-size-12">07 Oct, 2019</p>
                <p className="mb-1 font-size-15">Hub location:</p>
                <p className="mb-4 font-size-12">
                  Barcelona, El Raval&nbsp;&nbsp;&nbsp;
                  <span className="text-primary">Show on map {">"}</span>
                </p>
                <p className="mb-1 font-size-15">Current location:</p>
                <WrappedMap
                  init_zoom={13}
                  height="200px"
                  init_center={{ lat: 41.385381, lng: 2.173308 }}
                  markers_arr={[{ lat: 41.385381, lng: 2.173308 }]}
                />
              </div>
            </Col>
            <Col>
              <p className="mb-4 font-size-15">Statistics:</p>
              <p className="mb-1 font-size-15">Overall assignation time:</p>
              <p className="mb-4 font-size-12">720</p>
              <p className="mb-1 font-size-15">Hours on the road:</p>
              <p className="mb-4 font-size-12">576</p>
              <LineColumnArea />
              <Dountchart />
              <p className="mb-4 font-size-15">Notes:</p>
              <p className="mb-4 font-size-12">-</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
          <Link to="/history">
            <Button type="button" color="primary">
              Show history
            </Button>
          </Link>
          <Link to="/assignments">
            <Button type="button" color="primary">
              Assign/unassign from rider
            </Button>
          </Link>
          <Button type="button" color="danger" disabled>
            Report a problem
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default VehicleDetails;
