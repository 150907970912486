import React from "react";

interface SearchBarProps {
    placeholder: string;
    onValueChange: (value: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ placeholder, onValueChange }) => {

    return (
        <form className="search-box d-inline-block">
            <div className="position-relative">
                <input
                    style={{ backgroundColor: "#f3f3f9", border: "none" }}
                    type="text"
                    className="form-control"
                    placeholder={placeholder}
                    onChange={(e) => {
                        onValueChange(e.target.value)
                    }}
                />
                <i className="bx bx-search-alt search-icon" />
            </div>
        </form>
    );
}

export default SearchBar;