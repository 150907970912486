import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import scss
import "./assets/scss/theme.scss";

//layouts
import NonAuthLayout from "./components/NonAuthLayout";
import Layout from "./components/Layout/";

import "react-toastify/dist/ReactToastify.css";

function App() {
  function changeBodyAttribute(attribute: string, value: string) {
    if (document.body) document.body.setAttribute(attribute, value);
    return true;
  }

  useEffect(() => {
    changeBodyAttribute("data-layout", "horizontal");
    changeBodyAttribute("data-layout-size", "fluid");
    changeBodyAttribute("data-topbar", "dark");
    changeBodyAttribute("data-layout-size", "boxed");
  }, []);

  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          {authProtectedRoutes.map((route, idx) => (
            <Route key={idx} path={route.path} element={<route.component />} />
          ))}
        </Route>
        <Route element={<NonAuthLayout />}>
          {publicRoutes.map((route, idx) => (
            <Route key={idx} path={route.path} element={<route.component />} />
          ))}
        </Route>
      </Routes>
    </>
  );
}

export default App;
