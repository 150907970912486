import React from "react";
import WrappedMap from "../../Maps/GoogleMap/WrappedMap";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const MapModal = ({ isOpen, onClose, lat = 41.385381, lng = 2.173308 }) => {
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={() => {
        onClose();
      }}
    >
      <div>
        <ModalHeader
          toggle={() => {
            onClose();
          }}
        >
          Location
        </ModalHeader>
        <ModalBody>
          <WrappedMap
            init_zoom={13}
            height="300px"
            init_center={{ lat: lat, lng: lng }}
            markers_arr={[{ lat: lat, lng: lng }]}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default MapModal;
