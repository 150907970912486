import { toast } from "react-toastify";
import {
  unassignUserFromHub,
  assignUserToHubUser,
} from "../../../helpers/backend_helper";
import { IHub } from "../../../types";
import { BASKET_NAMES } from "./BasketNames";

const userAssignedToHub = () => toast.success("Assigned succesfully");
const userAssignedToHubError = () => toast.error("Assignation error");
const userUnassignedFromHub = () => toast.success("Unassigned succesfully");
const userUnassignedFromHubError = () => toast.error("Unassignation error");

export const createChangeUserBasket =
  (
    currentBasketName: string,
    baskets: any,
    hubSelected: IHub,
    setBaskets: any
  ) =>
  async (currentUser: { id: any; username?: string }, basketName: string) => {
    const basketChanger = () => {
      let retBaskets = [...baskets];
      const foundDecBasketIndex = retBaskets.findIndex(
        (basket) => basket.name === currentBasketName
      );
      const foundDecUserIndex = retBaskets[
        foundDecBasketIndex
      ].usersInside.findIndex(
        (user: { id: any }) => user.id === currentUser.id
      );
      const foundIncIndex = retBaskets.findIndex(
        (basket) => basket.name === basketName
      );

      retBaskets[foundDecBasketIndex].usersInside.splice(foundDecUserIndex, 1);
      retBaskets[foundIncIndex].usersInside.push(currentUser);
      return retBaskets;
    };
    if (currentBasketName === basketName) return;

    if (basketName === BASKET_NAMES.USERS) {
      await unassignUserFromHub(currentUser.id, hubSelected.id)
        .then(() => {
          setBaskets(basketChanger);
          toast.dismiss();
          userUnassignedFromHub();
        })
        .catch(() => {
          toast.dismiss();
          userUnassignedFromHubError();
        });
    } else if (basketName === BASKET_NAMES.ALL) {
      await assignUserToHubUser(currentUser.id, hubSelected.id)
        .then(() => {
          setBaskets(basketChanger);
          toast.dismiss();
          userAssignedToHub();
        })
        .catch(() => {
          toast.dismiss();
          userAssignedToHubError();
        });
    }
  };
