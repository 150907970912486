import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { useHttpClient } from "../../../context/HttpClient";
import * as yup from "yup";
import { IPasswordResetInput, IUser } from "../../../types";

interface IChangeRiderPasswordModalProps {
  rider: IUser | null;
  isOpen: boolean;
  onClose: () => void;
}

const resetPasswordSchema = yup
  .object({
    password: yup.string().min(8, "Password too short"),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords not match"),
  })
  .required();

export const ChangeRiderPasswordModal: FC<IChangeRiderPasswordModalProps> = ({
  rider,
  isOpen,
  onClose,
}) => {
  const {
    state: { postResetPassword },
  } = useHttpClient();

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
  } = useForm<IPasswordResetInput>({
    resolver: yupResolver(resetPasswordSchema),
  });

  const onSubmit = async (data: IPasswordResetInput) => {
    if (rider) {
      postResetPassword({ ...data, user_id: rider?.id });
      reset();
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex={-1}
      toggle={() => {
        onClose();
      }}
    >
      <div>
        <ModalHeader
          toggle={() => {
            onClose();
          }}
        >
          Change password
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <div className="mb-3">
              <Label className="form-label" for="password">
                Password
              </Label>
              <Controller
                control={control}
                name="password"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    id="password"
                    value={value}
                    type="password"
                    placeholder="Enter Password"
                    onChange={onChange}
                    onBlur={onBlur}
                    invalid={
                      dirtyFields.password && errors.password ? true : false
                    }
                  />
                )}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label" for="password">
                Password confirmation
              </Label>
              <Controller
                control={control}
                name="password_confirmation"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    id="password_confirmation"
                    value={value}
                    type="password"
                    placeholder="Confirm Password"
                    onChange={onChange}
                    onBlur={onBlur}
                    invalid={
                      dirtyFields.password_confirmation &&
                      errors.password_confirmation
                        ? true
                        : false
                    }
                  />
                )}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="secondary">
              Change password
            </Button>
          </ModalFooter>
        </form>
      </div>
    </Modal>
  );
};
