import React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";

import bike_icon from "../../../assets/images/bike-icon.png";
import scooter_icon from "../../../assets/images/scooter-icon.png";
import moped_icon from "../../../assets/images/moped-icon.png";
import cargo_bike_icon from "../../../assets/images/cargo-bike-icon.png";

const getIcon = (type) => {
  switch (type) {
    case "bike":
      return bike_icon;
    case "scooter":
      return scooter_icon;
    case "moped":
      return moped_icon;
    case "cargo":
      return cargo_bike_icon;
    default:
      return undefined;
  }
};

const render = (status) => {
  return <h1>{status}</h1>;
};

const WrappedMap = ({
  init_zoom,
  init_center,
  markers_arr = [],
  area = undefined,
  height,
  onMarkerClick,
}) => {
  const [markers, setMarkers] = React.useState([]);
  const [shape, setShape] = React.useState();
  const [zoom, setZoom] = React.useState(init_zoom);
  const [center, setCenter] = React.useState(init_center);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (loaded && markers_arr) {
      setMarkers(
        markers_arr.map((el) => ({
          latLng: new window.google.maps.LatLng(el.lat, el.lng),
          icon: getIcon(el.type),
          infoWindow: el.infoWindow,
        }))
      );
    }
  }, [loaded, markers_arr]);

  React.useEffect(() => {
    if (loaded && area) {
      setShape(area);
    }
  }, [loaded, area]);

  const onIdle = (m) => {
    if (!loaded) {
      setLoaded(true);
    }
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Wrapper
        apiKey={"AIzaSyDqelLavlRa81YHOA2LivyJjDyhCznqDps"}
        render={render}
      >
        <Map
          center={center}
          onIdle={onIdle}
          zoom={zoom}
          style={{ flexGrow: "1", height: height }}
        >
          {markers.map((marker, i) => (
            <Marker
              key={i}
              position={marker.latLng}
              icon={marker.icon}
              infoWindow={marker.infoWindow}
              onMarkerClick={onMarkerClick}
            />
          ))}
          {shape ? (
            <Shape
              path={shape}
              geodesic={true}
              strokeColor="#000000"
              strokeOpacity={1.0}
              strokeWeight={2}
            />
          ) : null}
        </Map>
      </Wrapper>
    </div>
  );
};

const Map = ({ onIdle, children, style, ...options }) => {
  const ref = React.useRef(null);
  const [map, setMap] = React.useState();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);
  React.useEffect(() => {
    if (map) {
      ["idle"].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onIdle]);
  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker = (options) => {
  const [marker, setMarker] = React.useState();
  const [listenerAdded, setListenerAdded] = React.useState(false);
  const [infowindow, setInfowindow] = React.useState();

  React.useEffect(() => {
    if (options) {
      setInfowindow(
        new window.google.maps.InfoWindow({
          content: options.infoWindow,
        })
      );
    }
  }, [options]);

  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
      if (!listenerAdded && infowindow) {
        marker.addListener("click", () => {
          options.onMarkerClick(options.infoWindow);
        });

        marker.addListener("mouseover", () => {
          infowindow.open({
            anchor: marker,
            shouldFocus: false,
          });
        });
        marker.addListener("mouseout", () => {
          infowindow.close();
        });
        setListenerAdded(true);
      }
    }
  }, [marker, options, listenerAdded, infowindow]);

  return null;
};

const Shape = (options) => {
  const [shape, setShape] = React.useState();
  const [listenerAdded, setListenerAdded] = React.useState(false);
  const [infowindow, setInfowindow] = React.useState();

  React.useEffect(() => {
    if (options) {
      setInfowindow(
        new window.google.maps.InfoWindow({
          content: "Here can be an any content",
          position: new window.google.maps.LatLng(
            options.path[2].lat,
            options.path[2].lng
          ),
        })
      );
    }
  }, [options]);

  React.useEffect(() => {
    if (!shape) {
      setShape(new window.google.maps.Polygon());
    }

    return () => {
      if (shape) {
        shape.setMap(null);
      }
    };
  }, [shape]);
  React.useEffect(() => {
    if (shape) {
      shape.setOptions(options);
      if (!listenerAdded && infowindow) {
        shape.addListener("mouseover", () => {
          infowindow.open({
            shouldFocus: false,
            map: shape.getMap(),
          });
        });

        shape.addListener("mouseout", () => {
          infowindow.close();
        });

        setListenerAdded(true);
      }
    }
  }, [shape, options, infowindow, listenerAdded]);

  return null;
};

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
  if (
    isLatLngLiteral(a) ||
    a instanceof window.google.maps.LatLng ||
    isLatLngLiteral(b) ||
    b instanceof window.google.maps.LatLng
  ) {
    return new window.google.maps.LatLng(a).equals(
      new window.google.maps.LatLng(b)
    );
  }

  return deepEqual(a, b);
});

function useDeepCompareMemoize(value) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
  // eslint-disable-next-line
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

export default WrappedMap;
