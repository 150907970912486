import { MouseEvent, memo } from "react";
import { useDrag } from "react-dnd";
import { BASKET_NAMES } from "./BasketNames";

import { ToastContainer } from "react-toastify";
import { IHub } from "../../../types.js";
import { createChangeUserBasket } from "./helpers";

interface IUserProps {
  id: number;
  baskets: any;
  username: string;
  setBaskets: any;
  currentBasketName: string;
  hubSelected: IHub;
  handleSelection: (
    args: Pick<MouseEvent<HTMLDivElement>, "metaKey" | "shiftKey"> & {
      index: number;
    }
  ) => void;
  index: number;
  isSelected: boolean;
}

export const User = memo(function User({
  id,
  baskets,
  username,
  setBaskets,
  currentBasketName,
  hubSelected,
  handleSelection,
  index,
  isSelected,
}: IUserProps) {
  const changeUserBasket = createChangeUserBasket(
    currentBasketName,
    baskets,
    hubSelected,
    setBaskets
  );
  const [{ isDragging }, drag, preview] = useDrag({
    item: { id, username },
    type: "USER",
    end: async (item, monitor) => {
      const dropResult = monitor.getDropResult<{ name: string }>();

      if (dropResult) {
        const { name } = dropResult;
        const { BIKES, SCOOTERS, MOPEDS, CARGO_BIKES, USERS, ALL } =
          BASKET_NAMES;
        switch (name) {
          case BIKES:
            await changeUserBasket(item, BIKES);
            break;
          case SCOOTERS:
            await changeUserBasket(item, SCOOTERS);
            break;
          case MOPEDS:
            await changeUserBasket(item, MOPEDS);
            break;
          case CARGO_BIKES:
            await changeUserBasket(item, CARGO_BIKES);
            break;
          case USERS:
            await changeUserBasket(item, USERS);
            break;
          case ALL:
            await changeUserBasket(item, ALL);
            break;
          default:
            break;
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  function handleRowSelection(metaKey: boolean, shiftKey: boolean, index: any) {
    handleSelection({ index, metaKey, shiftKey });
  }

  return (
    <div className="user-tab-wrapper">
      <div
        className="user-tab"
        ref={preview}
        style={{ opacity, backgroundColor: isSelected ? "#EEE2F8" : "#EFF2F7" }}
        data-testid="box"
      >
        <div
          ref={drag}
          className="user-tab-move"
          style={{
            borderRight: "#495057 dashed 2px",
            color: "#495057",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleRowSelection(e.metaKey, e.shiftKey, index);
          }}
        >
          <i className="bx bx-move"></i>
        </div>
        <div className="user-tab-content">
          <div>{id}</div>
          <div>{username}</div>
          <div></div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
});
