import React, { useEffect, useState } from "react";

import DataTable, { TableColumn } from "react-data-table-component";

import MapModal from "../../components/Modals/MapModal";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { useTranslation } from "react-i18next";

import { useAuthenticatedSWR } from "../../hooks/useAuthenticatedSWR";
import { IDock, IHub } from "../../types";
import Select from "react-select";
import { GET_DOCKS, GET_COMPANY_HUBS } from "../../helpers/url_helper";
import { usePaginatedSWR } from "../../hooks/usePagination";
import DockDetails from "../../components/Modals/DockDetails";
import { unlockDock } from "../../helpers/backend_helper";
import { Link } from "react-router-dom";

const Docks = () => {
  const { t } = useTranslation();
  const [selectedHubId, setSelectedHubId] = useState<string>("all");

  const {
    totalRows,
    entries: docks,
    getEntriesError: getDocksError,
    handlePageChange,
    handlePerRowsChange,
  } = usePaginatedSWR<IDock>({
    url: GET_DOCKS,
    queryParams: selectedHubId === "all" ? {} : { hub_id: selectedHubId },
    params: undefined,
  });
  const { data: hubs } = useAuthenticatedSWR<IHub[]>(`${GET_COMPANY_HUBS}/`);

  const [currentLocation, setCurrentLocation] = useState<{
    longitude: number;
    latitude: number;
  } | null>(null);

  const [textFilter, setTextFilter] = useState("");
  const [filteredResults, setFilteredResults] = useState<IDock[]>();

  useEffect(() => {
    if (docks) {
      setFilteredResults(
        docks.filter((dock) => {
          return dock.name.toLowerCase().includes(textFilter);
        })
      );
    }
  }, [docks, textFilter]);

  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [isDockDetailsModalOpen, setIsDockDetailsModalOpen] = useState(false);
  const [activeDockId, setActiveDockId] = useState<string | undefined>();

  const columns: TableColumn<IDock>[] = [
    {
      name: "ID",
      selector: (row) => row.device_id,
    },
    {
      name: "Dock name",
      selector: (row) => row.name,
    },
    {
      name: "Hub ID",
      selector: (row) => row.hub.id,
    },
    {
      name: "Hub location",
      cell: (row) => (
        <span
          className="link-primary"
          onClick={(e) => {
            e.preventDefault();
            setIsMapModalOpen(true);
            setCurrentLocation({
              longitude: parseFloat(row.hub.longitude),
              latitude: parseFloat(row.hub.latitude),
            });
          }}
        >
          {row.hub.address}
        </span>
      ),
    },
    {
      name: "Actions",
      grow: 3,
      cell: (row) => <Actions dockId={row.id} />,
    },
  ];

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={t("Home")} breadcrumbItem={t("Docks")} />
          <Row>
            <Col className="col-12">
              <Card style={{ height: "95%" }}>
                <CardBody>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <CardTitle>{t("Your docks list")}</CardTitle>
                    <form
                      className="search-box"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <div style={{ minWidth: "12rem", marginRight: "2rem" }}>
                        <Select
                          options={hubs
                            ?.map((hub) => ({
                              value: hub.id,
                              label: hub.name,
                            }))
                            .concat({
                              value: "all",
                              label: "All",
                            })}
                          onChange={(option) =>
                            setSelectedHubId(
                              option?.value ? option.value : "all"
                            )
                          }
                        />
                      </div>
                      <div className="position-relative">
                        <input
                          style={{ backgroundColor: "#f3f3f9", border: "none" }}
                          type="text"
                          className="form-control"
                          placeholder="Filter ..."
                          onChange={(e) => {
                            setTextFilter(e.target.value.toLowerCase());
                          }}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </form>
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredResults ?? []}
                    onRowClicked={(row) => {
                      setActiveDockId(row.id);
                      setIsDockDetailsModalOpen(true);
                    }}
                    conditionalRowStyles={[
                      {
                        when: (row) => !!row,
                        style: {
                          cursor: "pointer",
                        },
                      },
                    ]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressPending={!docks && !getDocksError}
                    highlightOnHover
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <MapModal
        isOpen={isMapModalOpen}
        lat={currentLocation?.latitude}
        lng={currentLocation?.longitude}
        onClose={() => setIsMapModalOpen(false)}
      />
      <DockDetails
        dockId={activeDockId}
        isOpen={isDockDetailsModalOpen}
        onClose={() => {
          setIsDockDetailsModalOpen(false);
          setActiveDockId(undefined);
        }}
      />
    </>
  );
};

interface IActionsProps {
  dockId: string;
}

const Actions = ({ dockId }: IActionsProps) => {
  const onUnlock = () => {
    unlockDock(dockId);
  };

  return (
    <>
      <div
        style={{
          width: "260px",
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          alignItems: "center",
          gap: "10px",
          wordBreak: "initial",
        }}
      >
        <Link onClick={onUnlock} to="#">
          Unlock dock
        </Link>
      </div>
    </>
  );
};

export default Docks;
