import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

//i18n
import { useTranslation } from "react-i18next";
// Redux

// users
import { useAuthState } from "../../../context/Auth";

const ProfileMenu = () => {
  const { t } = useTranslation();
  const {
    signOut,
    state: { user },
  } = useAuthState();
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="d-xl-inline-block ms-2 me-1">
            {user?.first_name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <span
            onClick={() => {
              signOut();
            }}
            className="dropdown-item cursor-pointer"
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{t("Logout")}</span>
          </span>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default ProfileMenu;
