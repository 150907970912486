import React, { Fragment, useEffect, useState } from "react";

//Components
import DataTable, { TableColumn, TableProps } from "react-data-table-component";
import WrappedMap from "../../components/Maps/GoogleMap/WrappedMap";

import MapModal from "../../components/Modals/MapModal";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DND from "../../components/UserAssign/DND";
import RiderDetails from "../../components/Modals/RiderDetails";

import { useDispatch, useSelector } from "react-redux";
import { toggleRidersModal } from "../../store/actions";

import { Row, Col, Button, Card, CardBody, CardTitle } from "reactstrap";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store";
import { IHub, MarkerTypeEnum } from "../../types";
import { GET_COMPANY_HUBS } from "../../helpers/url_helper";
import { usePaginatedSWR } from "../../hooks/usePagination";

const Assignments = () => {
  const dispatch = useDispatch();
  const isRiderDetailsOpen = useSelector<RootState>(
    (state) => state.Modals.isRidersModalVisible
  );
  const { t } = useTranslation();
  const [, setSelectedRows] = useState<IHub[]>([]);

  const [hubSelected, setHubSelected] = useState<IHub | null>(null);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<{
    longitude: number;
    latitude: number;
  } | null>(null);

  const columns: TableColumn<IHub>[] = [
    {
      name: "Hub name",
      selector: (row) => row.name,
    },
    {
      name: "Hub location",
      cell: (row) => (
        <span
          className="link-primary"
          onClick={(e) => {
            e.preventDefault();
            setIsMapModalOpen(true);
            setCurrentLocation({
              longitude: parseFloat(row.longitude),
              latitude: parseFloat(row.latitude),
            });
          }}
        >
          {row.address}
        </span>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => setHubSelected(row)}
        >
          Select
        </Button>
      ),
    },
  ];

  const {
    totalRows,
    entries: hubs,
    getEntriesError: getHubsError,
    handlePageChange,
    handlePerRowsChange,
  } = usePaginatedSWR<IHub>({
    url: GET_COMPANY_HUBS,
  });

  const [filteredResults, setFilteredResults] = useState<IHub[]>([]);
  useEffect(() => {
    if (hubs) {
      setFilteredResults(hubs);
    }
  }, [hubs]);

  const handleChange: TableProps<IHub>["onSelectedRowsChange"] = ({
    selectedRows,
  }) => {
    setSelectedRows(selectedRows);
  };

  return (
    <Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={t("Home")} breadcrumbItem={t("Assignments")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="mt-4">
                    <ul className="verti-timeline list-unstyled">
                      {/* Render Horizontal Timeline Events */}
                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <div className="status-circle-number">
                            <span>1</span>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="me-3">
                            <i className={"bx bx-copy-alt h2 text-primary"} />
                          </div>
                          <div className="flex-grow-1">
                            <div>
                              <h5>Select hub</h5>
                              <p className="text-muted">
                                Select a hub from those that you reserved
                                earlier in the list or on the map to/from which
                                you want to assign/unassign a user(s).
                              </p>
                            </div>
                          </div>
                        </div>
                        <Row>
                          <Col className="col-6">
                            {hubs && hubs.length && (
                              <WrappedMap
                                init_zoom={11}
                                height="300px"
                                init_center={{
                                  lat: parseFloat(hubs[0].latitude),
                                  lng: parseFloat(hubs[0].longitude),
                                }}
                                markers_arr={
                                  hubs?.map((hub) => ({
                                    lat: parseFloat(hub.latitude),
                                    lng: parseFloat(hub.longitude),
                                    infoWindow: hub.name,
                                    type: MarkerTypeEnum.HUB,
                                    // TODO
                                  })) as any
                                }
                                onMarkerClick={(selectedId: string) => {
                                  setFilteredResults(
                                    hubs?.filter(
                                      (hub) => hub.id === selectedId
                                    ) ?? []
                                  );
                                }}
                              />
                            )}
                          </Col>
                          <Col className="col-6">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <CardTitle>{t("List of hubs")}</CardTitle>
                              <Button
                                type="button"
                                color="primary"
                                className="btn-sm mb-3"
                                onClick={() => setFilteredResults(hubs ?? [])}
                              >
                                Reset filters
                              </Button>
                            </div>
                            <DataTable
                              columns={columns}
                              data={filteredResults}
                              pagination
                              paginationServer
                              paginationTotalRows={totalRows}
                              onChangeRowsPerPage={handlePerRowsChange}
                              onChangePage={handlePageChange}
                              progressPending={!hubs && !getHubsError}
                              selectableRows={false}
                              onSelectedRowsChange={handleChange}
                              highlightOnHover
                            />
                          </Col>
                        </Row>
                      </li>
                      {hubSelected ? (
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <div className="status-circle-number">
                              <span>2</span>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="me-3">
                              <i className={"bx bx-package h2 text-primary"} />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5>Manage assignation</h5>
                                <p className="text-muted">
                                  Here you can interactively change the
                                  assignment of users to specific types of
                                  vehicles by dragging and dropping
                                  (assign/unassign a user from the hub, change
                                  the number of vehiclers assigned to a user,
                                  and so on).
                                </p>
                              </div>
                            </div>
                          </div>
                          <DND
                            onHubChange={() => {
                              setHubSelected(null);
                            }}
                            hubSelected={hubSelected}
                          />
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <MapModal
        isOpen={isMapModalOpen}
        lat={currentLocation?.latitude}
        lng={currentLocation?.longitude}
        onClose={() => setIsMapModalOpen(false)}
      />
      <RiderDetails
        isOpen={isRiderDetailsOpen}
        onClose={() => dispatch(toggleRidersModal(false))}
      />
    </Fragment>
  );
};

export default Assignments;
