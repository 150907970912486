import {
  createContext,
  useContext,
  useMemo,
  FC,
  useEffect,
  useReducer,
  ReactNode,
} from "react";
import { useNavigate } from "react-router-dom";
import { axiosApi } from "../helpers/api_helper";
import { POST_LOGIN } from "../helpers/url_helper";
import { AuthActionTypeEnum, IAuthActions, ILoginInput, IUser } from "../types";

import { useHttpClient } from "./HttpClient";

interface IAuthState {
  accessToken?: string | null;
  isSignout: boolean;
  user?: IUser | null;
}

interface IAuthContext {
  signIn: (data: ILoginInput) => Promise<void>;
  signOut: () => void;
  state: IAuthState;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const useAuthState = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return context;
};

export const AuthStateProvider: FC<{ children: ReactNode }> = (props) => {
  const {
    state: { instance, getCurrentUser },
  } = useHttpClient();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(
    (prevState: IAuthState, action: IAuthActions): IAuthState => {
      switch (action.type) {
        case AuthActionTypeEnum.RESTORE_TOKEN:
        case AuthActionTypeEnum.SIGN_IN:
          return {
            ...prevState,
            isSignout: false,
            accessToken: action.accessToken,
            user: action.user,
          };
        case AuthActionTypeEnum.SIGN_OUT:
          return {
            ...prevState,
            isSignout: true,
            accessToken: null,
            user: null,
          };
        case AuthActionTypeEnum.REFETCH_USER:
          return {
            ...prevState,
            user: action.user,
          };
        default:
          return prevState;
      }
    },
    {
      isSignout: true,
      accessToken: null,
      user: null,
    }
  );

  useEffect(() => {
    // Fetch the token from storage then navigate to appropriate screen
    const bootstrapAsync = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const user = JSON.parse(localStorage.getItem("user") as string);

        if (accessToken && user) {
          axiosApi.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${accessToken}`;
          instance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${accessToken}`;
          dispatch({
            type: AuthActionTypeEnum.RESTORE_TOKEN,
            accessToken,
            user,
          });
          navigate("/hubs");
        } else {
          navigate("/login");
        }
      } catch (e) {
        // Restoring token failed
      }
    };

    bootstrapAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance.defaults.headers.common]);

  const value = useMemo(
    () => ({
      signIn: async (data: ILoginInput) => {
        const response = await instance({
          method: "POST",
          url: POST_LOGIN,
          data: `grant_type=password&scope=read+write&username=${encodeURIComponent(
            data.username
          )}&password=${encodeURIComponent(data.password)}`,
          auth: {
            username: process.env.REACT_APP_CLIENT_ID as string,
            password: process.env.REACT_APP_CLIENT_SECRET as string,
          },
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        });
        const accessToken = response.data.access_token as string;
        const refreshToken = response.data.refresh_token as string;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        axiosApi.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        instance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.access_token}`;

        const { data: userData } = await getCurrentUser();
        localStorage.setItem("user", JSON.stringify(userData));

        dispatch({
          type: AuthActionTypeEnum.SIGN_IN,
          accessToken,
          refreshToken,
          user: userData,
        });
        navigate("/hubs");
      },
      signOut: async () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        delete instance.defaults.headers.common["Authorization"];
        delete axiosApi.defaults.headers.common["Authorization"];
        dispatch({ type: AuthActionTypeEnum.SIGN_OUT });
        navigate("/login");
      },
      state,
    }),
    [getCurrentUser, instance, navigate, state]
  );

  return <AuthContext.Provider value={value} {...props} />;
};
