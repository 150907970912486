import React from "react";
import { Collapse } from "reactstrap";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//i18n
import { useTranslation } from "react-i18next";

// Redux Store
import { toggleLeftmenu } from "../../store/actions";

const Navbar = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const leftMenu = useSelector((state) => state.Layout.leftMenu);

  const menu = [
    {
      to: "/hubs",
      iconClass: "bx bx-tone me-2",
      text: t("Hubs"),
    },
    {
      to: "/riders",
      iconClass: "bx bx-customize me-2",
      text: t("Riders"),
    },
    {
      to: "/vehicles",
      iconClass: "bx bx-collection me-2",
      text: t("Vehicles"),
    },
    {
      to: "/docks",
      iconClass: "bx bx-collection me-2",
      text: t("Docks"),
    },
    {
      to: "/assignments",
      iconClass: "bx bx-file me-2",
      text: t("Assignments"),
    },
    {
      to: "/invitations",
      iconClass: "bx bx-file me-2",
      text: t("Invitations"),
    },
  ];

  return (
    <>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                {menu?.map(({ to, iconClass, text }, index) => (
                  <li key={index} className="nav-item dropdown">
                    <NavLink
                      className="nav-link dropdown-toggle arrow-none"
                      to={to}
                      onClick={() => {
                        !leftMenu || dispatch(toggleLeftmenu(!leftMenu));
                      }}
                    >
                      <i className={iconClass}></i>
                      {text}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Navbar;
