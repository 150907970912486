import { useState, useEffect, MouseEvent, useCallback } from "react";

export const useSelectItems = <T>(allItems?: T[]) => {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(-1);

  const clearItemSelection = () => {
    setSelectedItems([]);
    setLastSelectedIndex(-1);
  };

  const filterSelectedItems = useCallback(
    ({
      selectedItems,
      allItems,
      metaKey,
      shiftKey,
      index,
    }: {
      selectedItems: T[];
      allItems?: T[];
      metaKey: boolean;
      shiftKey: boolean;
      index: number;
    }) => {
      const item = index < 0 ? "" : allItems?.[index];
      if (!metaKey && !shiftKey) {
        return [item];
      } else if (shiftKey) {
        if (lastSelectedIndex >= index) {
          return selectedItems.concat(
            allItems?.slice(index, lastSelectedIndex) ?? []
          );
        } else {
          return selectedItems.concat(
            allItems?.slice(lastSelectedIndex + 1, index + 1) ?? []
          );
        }
      } else if (metaKey) {
        const foundIndex = selectedItems.findIndex((f) => f === item);
        // If found remove it to unselect it.
        if (foundIndex >= 0) {
          return [
            ...selectedItems.slice(0, foundIndex),
            ...selectedItems.slice(foundIndex + 1),
          ];
        } else {
          return [...selectedItems, item];
        }
      }
    },
    [lastSelectedIndex]
  );

  const handleItemSelection = useCallback(
    ({
      metaKey,
      shiftKey,
      index,
    }: Pick<MouseEvent<HTMLDivElement>, "metaKey" | "shiftKey"> & {
      index: number;
    }) => {
      const newSelectedItems = filterSelectedItems({
        selectedItems,
        allItems,
        metaKey,
        shiftKey,
        index,
      });
      const newLastSelectedIndex = index;
      const finalList = allItems
        ? allItems.filter((f) => newSelectedItems?.find((a) => a === f))
        : [];
      setSelectedItems(finalList);
      setLastSelectedIndex(newLastSelectedIndex);
    },
    [allItems, filterSelectedItems, selectedItems]
  );

  useEffect(() => {
    clearItemSelection();
  }, []);

  return {
    selectedItems,
    handleItemSelection,
    clearItemSelection,
    lastSelectedIndex,
  };
};
