import { Navigate } from "react-router-dom";

import Login from "../pages/Authentication/Login";
import Hubs from "../pages/Hubs";
import Assignments from "../pages/Assignments";
import Riders from "../pages/Riders";
import Vehicles from "../pages/Vehicles";
import Invitations from "../pages/Invitations";
import Docks from "../pages/Docks";

import E404 from "../pages/Error/pages-404";

const authProtectedRoutes = [
  //Pages
  { path: "/hubs", component: Hubs },
  { path: "/riders", component: Riders },
  { path: "/vehicles", component: Vehicles },
  { path: "/docks", component: Docks },
  { path: "/assignments", component: Assignments },
  { path: "/invitations", component: Invitations },

  //Routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Navigate to="/hubs" /> },
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "*", exact: true, component: E404 },
];

export { authProtectedRoutes, publicRoutes };
