import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Container } from "./Container";

const DND = ({ onHubChange, hubSelected }) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Container
        onHubChange={() => {
          onHubChange();
        }}
        hubSelected={hubSelected}
      />
    </DndProvider>
  );
};

export default DND;
