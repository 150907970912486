import { memo, useEffect, useState } from "react";
import { VehicleCategory } from "./VehicleCategory";
import { BASKET_NAMES } from "./BasketNames";
import { UsersBasket } from "./UsersBasket";

import SearchBar from "../../Common/CustomInputs/SearchBar";

import { Row, Col, Button } from "reactstrap";
import { IHub, IUser } from "../../../types";
import { GET_HUBS, GET_USERS } from "../../../helpers/url_helper";
import { usePaginatedSWR } from "../../../hooks/usePagination";

interface IContainerProps {
  onHubChange: () => void;
  hubSelected: IHub;
}

export const Container = memo(function Container({
  onHubChange,
  hubSelected,
}: IContainerProps) {
  const { entries: riders } = usePaginatedSWR<IUser>({
    url: `${GET_USERS}/`,
    queryParams: {
      limit: 9999,
    },
  });
  const { entries: ridersAssignedToCurrentHub } = usePaginatedSWR<IUser>({
    url: `${GET_HUBS}/${hubSelected.id}/assigned_to_hub/`,
    queryParams: { limit: 9999 },
  });

  const [baskets, setBaskets] = useState([
    {
      name: BASKET_NAMES.ALL,
      usersInside: [] as { id: number; username: string }[],
      avaliablePlaces: 99,
    },
    {
      name: BASKET_NAMES.USERS,
      usersInside: [] as { id: number; username: string }[],
      avaliablePlaces: undefined,
    },
  ]);

  useEffect(() => {
    if (ridersAssignedToCurrentHub && riders) {
      const ridersNotAssignedToCurrentHub = riders.filter(
        (rider) =>
          !ridersAssignedToCurrentHub.find(
            (riderAssigned) => riderAssigned.id === rider.id
          )
      );

      setBaskets([
        {
          name: BASKET_NAMES.ALL,
          usersInside:
            ridersAssignedToCurrentHub?.map(
              ({ id, first_name, last_name }) => ({
                id,
                username: `${first_name} ${last_name}`,
              })
            ) ?? [],
          avaliablePlaces: 99,
        },
        {
          name: BASKET_NAMES.USERS,
          usersInside: ridersNotAssignedToCurrentHub?.map(
            ({ id, first_name, last_name }) => ({
              id,
              username: `${first_name} ${last_name}`,
            })
          ),
          avaliablePlaces: undefined,
        },
      ]);
    }
  }, [riders, ridersAssignedToCurrentHub]);

  const [filterText, setFilterText] = useState("");

  return (
    <>
      <Row>
        <Col>
          <div
            style={{ overflow: "hidden", clear: "both" }}
            className="hub-box"
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5>{hubSelected.name}</h5>
              <Button
                color="primary"
                size="sm"
                className="btn-sm btn-rounded"
                onClick={() => {
                  onHubChange();
                }}
              >
                Change hub
              </Button>
            </div>
            {baskets
              .filter((basket) => basket.name !== BASKET_NAMES.USERS)
              .map(({ usersInside, name, avaliablePlaces }, index) => (
                <VehicleCategory
                  name={name}
                  avaliablePlaces={avaliablePlaces}
                  usersInside={usersInside}
                  baskets={baskets}
                  setBaskets={setBaskets}
                  key={index}
                  hubSelected={hubSelected}
                />
              ))}
          </div>
        </Col>
        <Col>
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h5>Unassigned users</h5>
            <SearchBar
              placeholder={"Search user"}
              onValueChange={setFilterText}
            />
          </div>
          <UsersBasket
            name={BASKET_NAMES.USERS}
            usersInside={
              baskets?.find(({ name }) => name === BASKET_NAMES.USERS)
                ?.usersInside
            }
            baskets={baskets}
            setBaskets={setBaskets}
            filter={filterText}
            hubSelected={hubSelected}
          />
        </Col>
      </Row>
    </>
  );
});
