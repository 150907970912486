import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";

const AlertModal = ({ isOpen, onClose, onSubmit }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          onClose();
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              onClose();
            }}
          >
            Warning
          </ModalHeader>
          <ModalBody>
            <div className="p-2">
              <div className="text-center">
                <div className="avatar-md mx-auto">
                  <div className="avatar-title rounded-circle bg-light">
                    <i className="bx bx-error-circle h1 mb-0 text-warning"></i>
                  </div>
                </div>
                <div className="p-2 mt-4">
                  <h4>Are you sure?</h4>
                  <p className="text-muted">
                    {
                      "Are you sure you want to delete this invitation? This action is irrevertible."
                    }
                  </p>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </Button>
            <Button
              type="button"
              color={"warning"}
              onClick={() => {
                onSubmit();
              }}
            >
              Ok
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

export default AlertModal;
