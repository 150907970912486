import React, { useEffect } from "react";

//Redux
import { Outlet } from "react-router-dom";

//components
import Navbar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";
import { useAuthState } from "../../context/Auth";

const Layout = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    state: { user },
  } = useAuthState();

  return user ? (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  ) : (
    <>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
