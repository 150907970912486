import { AxiosError } from "axios";
import useSWR from "swr";
import { BareFetcher, SWRConfiguration } from "swr/dist/types";
import { useAuthState } from "../context/Auth";
import { useHttpClient } from "../context/HttpClient";

export const useAuthenticatedSWR = <R, P = {}>(
  url: string,
  params?: P,
  config?: SWRConfiguration<R, AxiosError, BareFetcher<R>> | undefined
) => {
  const {
    state: { user },
  } = useAuthState();
  const {
    state: { instance },
  } = useHttpClient();

  const { data, error } = useSWR<R, AxiosError>(
    [user && url ? url : null, params && { params }],
    async (url, config) => {
      if (!url) return Promise.reject();
      const res = await instance.get(url, config);
      return res.data;
    },
    config
  );

  return { data, error };
};
