import { axiosApi } from "./api_helper";
import * as url from "./url_helper";

// Login Method
export const postLogin = (data) => {
  return axiosApi({
    method: "POST",
    url: url.POST_LOGIN,
    data: `grant_type=password&scope=read+write&username=${encodeURIComponent(
      data.username
    )}&password=${encodeURIComponent(data.password)}`,
    auth: {
      username: process.env.REACT_APP_CLIENT_ID,
      password: process.env.REACT_APP_CLIENT_SECRET,
    },
    headers: {
      accept: "application/json",
      "content-type": "application/x-www-form-urlencoded",
      "x-requested-with": "XMLHttpRequest",
    },
  });
};

export const getNewTokenWithRefreshToken = (refreshToken) => {
  return axiosApi({
    method: "POST",
    url: url.POST_LOGIN,
    data: `grant_type=refresh_token&refresh_token=${refreshToken}`,
    auth: {
      username: process.env.REACT_APP_CLIENT_ID,
      password: process.env.REACT_APP_CLIENT_SECRET,
    },
    headers: {
      accept: "application/json",
      "content-type": "application/x-www-form-urlencoded",
      "x-requested-with": "XMLHttpRequest",
    },
  });
};

//Get Hubs
export const getListOfHubs = () => {
  return axiosApi({
    method: "GET",
    url: url.GET_COMPANY_HUBS,
    headers: {
      accept: "application/json",
      "content-type": "application/x-www-form-urlencoded",
      "x-requested-with": "XMLHttpRequest",
    },
  });
};

export const getListOfRiders = () => {
  return axiosApi({
    method: "GET",
    url: "/v1/users/",
    headers: {
      accept: "application/json",
      "content-type": "application/x-www-form-urlencoded",
      "x-requested-with": "XMLHttpRequest",
    },
  });
};

export const getListOfHubsAvaliableForUser = (userId) => {
  return axiosApi({
    method: "GET",
    url: `/v1/users/${userId}/hubs/`,
    headers: {
      accept: "application/json",
      "content-type": "application/x-www-form-urlencoded",
      "x-requested-with": "XMLHttpRequest",
    },
  });
};

export const assignUserToHubUser = (userId, hubId) => {
  return axiosApi({
    method: "POST",
    url: `/v1/users/${userId}/hubs/`,
    headers: {
      accept: "application/json",
      "content-type": "application/json;charset=utf-8",
      // "x-requested-with": "XMLHttpRequest",
    },
    data: {
      id: hubId,
    },
  });
};

export const unassignUserFromHub = (userId, hubId) => {
  return axiosApi({
    method: "DELETE",
    url: `/v1/users/${userId}/hubs/${hubId}/`,
    headers: {
      accept: "application/json",
      "content-type": "application/json;charset=utf-8",
      // "x-requested-with": "XMLHttpRequest",
    },
  });
};

export const deleteAnInvitation = (id) => {
  return axiosApi({
    method: "PATCH",
    url: `/v1/invitations/${id}/`,
    headers: {
      accept: "application/json",
      "content-type": "application/json;charset=utf-8",
      // "x-requested-with": "XMLHttpRequest",
    },
    data: {
      status: 3,
    },
  });
};

export const getCurentUser = () => {
  return axiosApi({
    method: "GET",
    url: "/v1/users/current/",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      // "x-requested-with": "XMLHttpRequest",
    },
  });
};

export const unlockDock = (dockId) => {
  return axiosApi({
    method: "POST",
    url: `/v1/docks/${dockId}/unlock/`,
  });
};
