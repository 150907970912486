import axios, { AxiosError, AxiosInstance, AxiosPromise } from "axios";
import {
  createContext,
  useContext,
  useMemo,
  FC,
  ReactNode,
  useRef,
  useEffect,
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { axiosApi } from "../helpers/api_helper";
import { getNewTokenWithRefreshToken } from "../helpers/backend_helper";
import {
  GET_CURRENT_USER,
  GET_INVITATIONS,
  POST_RESET_PASSWORD,
} from "../helpers/url_helper";
import {
  ICreateInvitationInput,
  IPostResetPasswordInput,
  IUpdateInvitationInput,
  IUser,
} from "../types";

interface IHttpClientState {
  instance: AxiosInstance;
  getCurrentUser: () => AxiosPromise<IUser>;
  postResetPassword: (data: IPostResetPasswordInput) => AxiosPromise<unknown>;
  updateInvitation: (data: IUpdateInvitationInput) => AxiosPromise<unknown>;
  createInvitation: (data: ICreateInvitationInput) => AxiosPromise<unknown>;
  deleteInvitation: (id: number) => AxiosPromise<unknown>;
}

interface IHttpClientContext {
  state: IHttpClientState;
}

const HttpClientContext = createContext<IHttpClientContext>(
  {} as IHttpClientContext
);

export const useHttpClient = () => {
  const context = useContext(HttpClientContext);
  if (!context) {
    throw new Error("useHttpClient must be used within a HttpClientProvider");
  }
  return context;
};

export const HttpClientStateProvider: FC<{ children: ReactNode }> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const instance = useRef(
    axios.create({
      // @ts-ignore
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        accept: "application/json",
      },
    })
  );

  useEffect(() => {
    const onError = (error: any) => {
      const refreshToken = localStorage.getItem("refreshToken");
      if (
        refreshToken &&
        !error.config._retry &&
        error.response.status === 401 &&
        error.response.config.url !== "/oauth/token/"
      ) {
        error.config._retry = true;

        delete instance.current.defaults.headers.common["Authorization"];
        getNewTokenWithRefreshToken(refreshToken)
          .then((resp) => {
            localStorage.setItem("accessToken", resp.data.access_token);
            localStorage.setItem("refreshToken", resp.data.refresh_token);
            instance.current.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${resp.data.access_token}`;
            navigate(0);
            return;
          })
          .catch((err: AxiosError<{ error: string }>) => {
            if (err.response?.data?.error === "invalid_grant") {
              localStorage.removeItem("accessToken");
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("user");
              navigate("/login");
              return Promise.reject(err);
            }
          });
      } else {
        return Promise.reject(error);
      }
    };

    axiosApi.interceptors.response.use((response) => response, onError);
    instance.current.interceptors.response.use((response) => response, onError);
  }, [dispatch, navigate]);

  const getCurrentUser = () => {
    return instance.current.get<IUser>(GET_CURRENT_USER);
  };

  const postResetPassword = (data: IPostResetPasswordInput) => {
    return instance.current.post(POST_RESET_PASSWORD, data, {
      headers: {
        Accept: "application/json",
      },
    });
  };

  const updateInvitation = (data: IUpdateInvitationInput) => {
    const { id, ...rest } = data;
    return instance.current.patch(`${GET_INVITATIONS}/${id}/`, rest, {
      headers: {
        accept: "application/json",
        "content-type": "application/json;charset=utf-8",
      },
    });
  };

  const createInvitation = (data: ICreateInvitationInput) => {
    return instance.current.post(`${GET_INVITATIONS}/`, data, {
      headers: {
        accept: "application/json",
        "content-type": "application/json;charset=utf-8",
      },
    });
  };

  const deleteInvitation = (id: number) => {
    return instance.current.patch(
      `${GET_INVITATIONS}/${id}/`,
      { status: 3 },
      {
        headers: {
          accept: "application/json",
          "content-type": "application/json;charset=utf-8",
        },
      }
    );
  };

  const value: IHttpClientContext = useMemo(
    () => ({
      state: {
        instance: instance.current,
        getCurrentUser,
        postResetPassword,
        updateInvitation,
        createInvitation,
        deleteInvitation,
      },
    }),
    []
  );

  return <HttpClientContext.Provider value={value} {...props} />;
};
