import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const InvitationDetails = ({ data, onClose }) => {
  return (
    <>
      <Modal
        isOpen={!!data}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          onClose();
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              onClose();
            }}
          >
            Invitation details
          </ModalHeader>
          <ModalBody>
            {!!data ? (
              <div style={{ margin: "0 10px" }}>
                <p className="mb-1 font-size-15">Phone:</p>
                <p className="mb-4 font-size-12">{data.phone_number}</p>
                <p className="mb-1 font-size-15">First name:</p>
                <p className="mb-4 font-size-12">
                  {data.first_name ? data.first_name : "<blank>"}
                </p>
                <p className="mb-1 font-size-15">Last name:</p>
                <p className="mb-4 font-size-12">
                  {data.last_name ? data.last_name : "<blank>"}
                </p>
                <p className="mb-1 font-size-15">Email:</p>
                <p className="mb-4 font-size-12">
                  {data.email ? data.email : "<blank>"}
                </p>
                <p className="mb-1 font-size-15">Notes:</p>
                <p className="mb-4 font-size-12">
                  {data.notes ? data.notes : "<blank>"}
                </p>
              </div>
            ) : null}
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};

export default InvitationDetails;
