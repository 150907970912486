import React, { memo } from "react";
import { useDrop } from "react-dnd";
import { Button } from "reactstrap";
import { IHub } from "../../../types";
import { BASKET_NAMES } from "./BasketNames";
import { createChangeUserBasket } from "./helpers";
import { User } from "./User";
import { useSelectItems } from "./useSelectUsers";

interface IUsersBasketProps {
  name: string;
  usersInside?: { username: string; id: number }[];
  baskets: any[];
  setBaskets: any;
  filter: string;
  hubSelected: IHub;
}

export const UsersBasket = memo(function UsersBasket({
  name,
  usersInside,
  baskets,
  setBaskets,
  filter,
  hubSelected,
}: IUsersBasketProps) {
  const { selectedItems, handleItemSelection, clearItemSelection } =
    useSelectItems(usersInside);

  const [{ isOver, canDrop, beginDrag }, drop] = useDrop({
    accept: "USER",
    drop: () => ({ name: name }),
    collect: (monitor) => ({
      beginDrag: monitor.getItem(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = isOver && canDrop && beginDrag;
  let backgroundColor = "#fff";
  if (isActive) {
    backgroundColor = "#82E0AA";
  } else if (canDrop && beginDrag) {
    backgroundColor = "#ABEBC6";
  } else if (beginDrag) {
    backgroundColor = "#E74C3C";
  }
  const changeUserBasket = createChangeUserBasket(
    name,
    baskets,
    hubSelected,
    setBaskets
  );

  const switchBasket = async ({
    item,
  }: {
    item: { id: number; username: string };
  }) => {
    await changeUserBasket(item, BASKET_NAMES.ALL);
  };

  return (
    <>
      <div
        ref={drop}
        style={{ backgroundColor }}
        data-testid="users-basket"
        className="users-basket"
      >
        {selectedItems.length > 0 && (
          <Button
            color="primary"
            size="sm"
            className="btn-sm btn-rounded"
            onClick={async () => {
              await Promise.all(
                selectedItems.map(async (item) => {
                  await switchBasket({ item });
                })
              );
              clearItemSelection();
            }}
          >
            Assign users
          </Button>
        )}
        <div className="user-table">
          {usersInside
            ?.filter(({ username }) =>
              username.toLowerCase().includes(filter.toLowerCase())
            )
            .sort((a, b) => a.username?.localeCompare(b.username))
            .map(({ id, username }, index) => (
              <User
                key={index}
                id={id}
                username={username}
                currentBasketName={name}
                baskets={baskets}
                setBaskets={setBaskets}
                hubSelected={hubSelected}
                handleSelection={handleItemSelection}
                index={index}
                isSelected={
                  selectedItems.filter((item) => item.id === id).length > 0
                }
              />
            ))}
        </div>
      </div>
    </>
  );
});
