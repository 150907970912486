import { AxiosError } from "axios";
import queryString from "query-string";
import { useEffect, useState } from "react";
import {
  PaginationChangePage,
  PaginationChangeRowsPerPage,
} from "react-data-table-component/dist/src/DataTable/types";
import { BareFetcher, SWRConfiguration } from "swr";
import { IPaginatedResult } from "../types";
import { useAuthenticatedSWR } from "./useAuthenticatedSWR";

const usePagination = () => {
  const [totalRows, setTotalRows] = useState(0);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [currentLimit, setCurrentLimit] = useState(10);

  const handlePageChange: PaginationChangePage = (page) => {
    setCurrentOffset(page * currentLimit);
  };

  const handlePerRowsChange: PaginationChangeRowsPerPage = async (
    newPerPage
  ) => {
    setCurrentLimit(newPerPage);
    setCurrentOffset(0);
  };

  return {
    totalRows,
    setTotalRows,
    currentOffset,
    currentLimit,
    handlePageChange,
    handlePerRowsChange,
  };
};

export interface IPaginatedSWRInput<Q, P, R> {
  url: string;
  queryParams?: Q;
  params?: P;
  config?:
    | SWRConfiguration<
        IPaginatedResult<R>,
        AxiosError,
        BareFetcher<IPaginatedResult<R>>
      >
    | undefined;
}

export const usePaginatedSWR = <R, Q = {}, P = {}>({
  url,
  queryParams,
  params,
  config,
}: IPaginatedSWRInput<Q, P, R>) => {
  const {
    totalRows,
    setTotalRows,
    currentOffset,
    currentLimit,
    handlePageChange,
    handlePerRowsChange,
  } = usePagination();

  const stringifiedParams = queryString.stringify({
    limit: currentLimit,
    offset: currentOffset,
    ...queryParams,
  });

  const { data, error: getEntriesError } = useAuthenticatedSWR<
    IPaginatedResult<R>,
    P
  >(`${url}?${stringifiedParams}`, params, config);

  useEffect(() => {
    if (data && "count" in data) {
      setTotalRows(data.count);
    } else if (data) {
      setTotalRows(data.length);
    }
  }, [data, setTotalRows]);

  return {
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    entries: data && "results" in data ? data?.results : data,
    getEntriesError,
  };
};
