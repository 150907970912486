import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { IInvitation } from "../../../types";
import InvitationForm from "../../Common/Forms/InvitationForm";

interface IInvitationFormModalProps {
  title: string;
  data?: IInvitation;
  isOpen: boolean;
  onClose: () => void;
}

const InvitationFormModal = ({
  title,
  data,
  isOpen,
  onClose,
}: IInvitationFormModalProps) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        toggle={() => {
          onClose();
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              onClose();
            }}
          >
            {title}
          </ModalHeader>
          <ModalBody>
            <InvitationForm
              invitationFields={data}
              onFormSubmit={() => onClose()}
            />
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};

export default InvitationFormModal;
