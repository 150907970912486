import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

//Import Images
import error from "../../assets/images/error-img.png";

const Pages404 = () => {
  return (
    <>
      {/* <MetaTags>
          <title>404 Error Page | Skote - React Admin & Dashboard Template</title>
        </MetaTags> */}
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 font-weight-medium">
                  4<i className="bx bx-buoy bx-spin text-primary display-3" />4
                </h1>
                <h4 className="text-uppercase">Sorry, page not found</h4>
                <div className="mt-5 text-center">
                  <Link className="btn btn-primary " to={"/hubs"}>
                    Back to Hubs
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Pages404;
