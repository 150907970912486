import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";

const AlertModal = ({ isOpen, onClose, type, description }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          onClose();
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              onClose();
            }}
          >
            {type}
          </ModalHeader>
          <ModalBody>
            <div className="p-2">
              <div className="text-center">
                <div className="avatar-md mx-auto">
                  <div className="avatar-title rounded-circle bg-light">
                    {type === "Success" ? (
                      <i className="bx bx-check-circle h1 mb-0 text-success"></i>
                    ) : type === "Warning" ? (
                      <i className="bx bx-error-circle h1 mb-0 text-warning"></i>
                    ) : type === "Error" ? (
                      <i className="bx bx-x-circle h1 mb-0 text-danger"></i>
                    ) : null}
                  </div>
                </div>
                <div className="p-2 mt-4">
                  <h4>{type}</h4>
                  <p className="text-muted">{description}</p>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </Button>
            <Button
              type="button"
              color={
                type === "Success"
                  ? "success"
                  : type === "Warning"
                  ? "warning"
                  : type === "Error"
                  ? "danger"
                  : "secondary"
              }
              onClick={() => {
                onClose();
              }}
            >
              Ok
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

export default AlertModal;
