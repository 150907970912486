import { combineReducers } from "redux";

//Layout
import Layout from "./layout/reducer";

//Modals
import Modals from "./modals/reducer";

const rootReducer = combineReducers({
  Layout,
  Modals,
});

export default rootReducer;
