import { FC, ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";

import * as urls from "../helpers/url_helper";
import { INotification } from "../types";
import { useAuthenticatedSWR } from "../hooks/useAuthenticatedSWR";

interface INotificationsContext {
  notifications?: INotification[];
}

const NotificationsContext = createContext<INotificationsContext>({});

interface INotificationsProviderProps {
  children: ReactNode;
}

export const NotificationsProvider: FC<INotificationsProviderProps> = ({
  children,
}) => {
  const { data: notifications } = useAuthenticatedSWR<INotification[]>(
    urls.GET_NOTIFICATIONS
  );

  const value = useMemo(
    () => ({
      notifications,
    }),
    [notifications]
  );

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationsState = () => {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error(
      "useNotificationsState must be used within a NotificationsProvider"
    );
  }
  return context;
};
