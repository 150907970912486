import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";


const Dountchart = () => {
  const series = [44, 55, 41];
  const options: ApexOptions  = {
    labels: ["Being used", "Charging", "Charged not used"],
    colors: ["#34c38f", "#556ee6", "#f1b44c"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 140,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height="280"
    />
  );
};

export default Dountchart;
